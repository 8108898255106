import { cn, formatExpiration } from '@/lib/utils/generalUtils';
import {
  CalendarIcon,
  ClockIcon,
  InformationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/outline';
import { TrendingUpIcon } from '@heroicons/react/solid';
import StatusLabel from './statusLabel';
import Tooltip from './tooltip';

const IsDoFollowLabel = ({ isDoFollow, doFollowLinksAllowed }) => {
  let content;
  if (isDoFollow) {
    content = (
      <>
        <p>The article will be indexed and ranked on search engines</p>
      </>
    );
    if (doFollowLinksAllowed) {
      content = (
        <>
          <p>
            The article will be indexed and ranked on search engines
            <br />
            <br />
            Links Allowed: <b>{doFollowLinksAllowed}</b>
          </p>
        </>
      );
    }
  } else {
    content =
      'The publication is not indexed and will not be ranked on search engines';
  }

  const title = isDoFollow ? 'Do Follow' : 'No Follow';

  return (
    <Tooltip content={content}>
      <StatusLabel title={title} status={isDoFollow ? 0 : 3} />
    </Tooltip>
  );
};

const ImageLabel = ({ imageRequired }) => {
  var message;
  var title;
  var status = 3;

  if (imageRequired == 'Y') {
    title = 'Image Required';
    message = 'Image are required for this publication';
    status = 0;
  } else if (imageRequired == 'N') {
    title = 'Image Not Required';
    message = 'Image are not required for this publication';
    status = 4;
  } else if (imageRequired == 'YN') {
    title = 'Image May Be Required';
    message = 'Image may be required for this publication';
    status = 2;
  }

  return (
    <Tooltip content={message}>
      <StatusLabel title={title} status={status} />
    </Tooltip>
  );
};

const SponsorLabel = ({ isSponsored }) => {
  var message;
  var title;
  var status = 3;

  if (isSponsored == 'Y') {
    title = 'Sponsored';
    message =
      'Sponsored articles may mention sponsored, paid post, or something similar';
  } else if (isSponsored == 'N (Disclaimer)') {
    title = 'Disclaimer';
    message =
      'Articles may contain ‘Presented by writers name’ or something similar';
    // status = 2;
  } else if (isSponsored == 'N') {
    return <></>;
    title = 'Non-Sponsored';
    message =
      'Non-sponsored articles will have no sponsored disclaimers, but in some cases may contain ‘Written in partnership with Writer’s Name’, ‘Presented by Writer’s Name’ or something similar';
    status = 0;
  } else if (isSponsored == 'YN') {
    // title = "Non-Sponsored"
    // message = "Non-sponsored articles will have no sponsored disclaimers, but in some cases may contain ‘Written in partnership with Writer’s Name’, ‘Presented by Writer’s Name’ or something similar"
  }

  return (
    <Tooltip content={message}>
      <StatusLabel title={title} status={status} />
    </Tooltip>
  );
};

const NewsLabel = ({ news }) => {
  var message;
  var title;
  var status;
  if (news == 'Y') {
    title = 'Google News';
    message = 'Your article will be Google News eligible';
    status = 0;
  } else if (news == 'M') {
    title = 'Google News';
    message = 'Your page may or may not show in Google news results';
    status = 2;
  } else if (news == 'N') {
    title = 'No Google News';
    message = 'Your page will not show in Google news results';
    status = 3;
  }

  return (
    <Tooltip content={message}>
      <StatusLabel title={title} status={status} />
    </Tooltip>
  );
};

const IndexLabel = ({ indexed }) => {
  var message;
  var title;
  var status;
  if (indexed == 'Y') {
    title = 'Indexed';
    message = 'Your page will show in search results.';
    status = 0;
  } else if (indexed == 'M') {
    title = 'Indexed*';
    message = 'Your page may or may not show in search results';
    status = 2;
  } else if (indexed == 'N') {
    title = 'Not Indexed';
    message = 'Your page will not show in search results';
    status = 3;
  }

  return (
    <Tooltip content={message}>
      <StatusLabel title={title} status={status} />
    </Tooltip>
  );
};

const DisclaimerLabel = ({ disclaimer }) => {
  var message;
  var title;
  var status;
  // if (disclaimer == "staff") {
  //   title = "Staff";
  //   message = "Articles from Staff contain no disclaimers.";
  //   status = 5;
  // } else if (disclaimer == "contributor") {
  //   title = "Contributor";
  //   message =
  //     'Contributors are freelance experts, not full-time staff. Their articles may include phrases like "Presented by" or "Written in Partnership with".';
  //   status = 7;
  if (disclaimer == 'sponsored') {
    title = 'Sponsored';
    message =
      'Advertised articles are labeled "Sponsored Post" and may have added disclaimers. They are promotional by design.';
  } else {
    title = 'Contributor';
    message =
      'Contributors are freelance experts, not full-time staff. Their articles may include phrases like "Presented by" or "Written in Partnership with".';
    status = 7;
  }

  return (
    <Tooltip content={message}>
      <StatusLabel title={title} status={status} />
    </Tooltip>
  );
};

const TurnaroundTimeLabel = ({ turnaroundTime }) => {
  return (
    <span className="flex lg:flex gap-1 items-center">
      <div className="flex items-center gap-1">
        <CalendarIcon className="h-4 w-4 text-gray-500" />
        Turnaround time
      </div>{' '}
      <Tooltip content="The estimated time after you approve the article before it is published and live">
        <span className="flex items-center gap-1">
          <b>~{turnaroundTime}</b>
          <InformationCircleIcon className="h-4 w-4" />
        </span>
      </Tooltip>
    </span>
  );
};

const ESTTurnaroundTimeLabel = ({ turnaroundTime }) => {
  return (
    <div className="flex lg:flex gap-2 items-start">
      <div className="mt-0.5">
        <CalendarIcon className="h-4 w-4 text-gray-500" />
      </div>
      <div className="flex-1 text-gray-500 text-sm flex items-center gap-x-2 flex-wrap">
        <p>Est. Turnaround Time</p>
        <Tooltip content="The estimated time after you approve the article before it is published and live">
          <p className="flex items-center gap-1">
            <span className="font-bold">around {turnaroundTime}</span>
            <QuestionMarkCircleIcon className="h-4 w-4" />
          </p>
        </Tooltip>
      </div>
    </div>
  );
};

const DomainAuthorityRankingLabel = ({ domainAuthority, domainRanking }) => {
  return (
    <div className="flex flex-wrap items-start gap-2">
      <TrendingUpIcon className="w-4 h-4 text-gray-400 mt-0.5" />
      <div className="flex-1 flex items-center flex-wrap gap-x-3 text-gray-500">
        <p>
          Domain Authority
          <span className="font-bold"> {domainAuthority}</span>
        </p>
        <p>
          Domain Ranking <span className="font-bold"> {domainRanking}</span>
        </p>
      </div>
    </div>
  );
};

const SponsorLabel2 = ({ isSponsored }) => {
  var message;
  var title;
  var status = 3;

  if (isSponsored === 'Y') {
    title = 'Sponsored';
    message =
      'Sponsored articles may mention sponsored, paid post, or something similar';
  } else if (isSponsored === 'N (Disclaimer)') {
    title = 'Disclaimer';
    message =
      'Articles may contain ‘Presented by writers name’ or something similar';
    // status = 2;
  } else if (isSponsored === 'N') {
    title = 'Not Sponsored';
    message =
      'Not sponsored articles will have no sponsored disclaimers, but in some cases may contain ‘Written in partnership with Writer’s Name’, ‘Presented by Writer’s Name’ or something similar';
    status = 0;
  } else if (isSponsored === 'YN') {
    // title = "Non-Sponsored"
    // message = "Non-sponsored articles will have no sponsored disclaimers, but in some cases may contain ‘Written in partnership with Writer’s Name’, ‘Presented by Writer’s Name’ or something similar"
  }

  return (
    <Tooltip content={message}>
      <StatusLabel title={title} status={status} />
    </Tooltip>
  );
};

function DealExpirationStatus({ endDate, className }) {
  if (!endDate) {
    return null;
  }

  return (
    <p
      className={cn(
        'uppercase inline-flex items-center text-[0.625rem] font-bold text-purple-600 bg-purple-100 py-px px-2 rounded-full',
        className
      )}
    >
      <ClockIcon className="w-3 h-3 mr-1" />
      {formatExpiration(endDate)}
    </p>
  );
}

export {
  DealExpirationStatus,
  DisclaimerLabel,
  DomainAuthorityRankingLabel,
  ESTTurnaroundTimeLabel,
  ImageLabel,
  IndexLabel,
  IsDoFollowLabel,
  NewsLabel,
  SponsorLabel,
  SponsorLabel2,
  TurnaroundTimeLabel,
};
